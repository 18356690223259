import {
  Routes,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';

// components

import Layout from "components/Layout";

// pages
import LandingPage from "pages/LandingPage";
import CareerPath from "pages/CareerPath";
import CareerPathDetail from "pages/CareerPathDetail";

const theme = createTheme({
  palette: {
    primary: {
      main: '#4169E1',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="" element={<LandingPage />} />
            <Route path="career-path" element={<CareerPath />} />
            <Route path="career-path/:slug" element={<CareerPathDetail />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
   
  );
}

export default App;
