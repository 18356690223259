/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';

// mui core
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

// assets
import ImgHeroBanner from 'assets/herobanner.png';
import ImgCreators01 from 'assets/creators-01.png';
import ImgCreators02 from 'assets/creators-02.png';
import ImgCreators03 from 'assets/creators-03.png';
import ImgDeveloperBanner from 'assets/developer-banner.png';
import ImgArtistBanner from 'assets/artist-banner.png';
import ImgCommunityBanner from 'assets/community-banner.png';
import ImgFeature01 from 'assets/feature-01.png';
import ImgFeature02 from 'assets/feature-02.png';
import ImgFeature03 from 'assets/feature-03.png';
import IconSolana from 'assets/chains/solana.png';
import IconRonin from 'assets/chains/ronin.png';
import DualIchor from 'assets/dual-ichor.png';
import DualAmbros from 'assets/dual-ambros.png';
import ImgLetter from 'assets/img-letter.png';
import { ReactComponent as IconRoadmap } from "assets/svg/icon-roadmap.svg";
import { ReactComponent as IconAmbros } from "assets/chains/ambros.svg";
import { ReactComponent as IconEtherium } from "assets/chains/etherium.svg";
import { ReactComponent as IconBinance } from "assets/chains/binance.svg";
import { ReactComponent as IconChecked } from "assets/svg/icon-checked.svg";
import { ReactComponent as IconReject } from "assets/svg/icon-reject.svg";

// import ImgEversecapital from 'assets/leading/eversecapital.png';
// import ImgBasicCaptial from 'assets/leading/basic-capital.png';
// import ImgAnimoca from 'assets/leading/animoca.png';
// import ImgDoulbePeek from 'assets/leading/double-peak.png';
// import ImgDline from 'assets/leading/d-line.png';
// import ImgPolygon from 'assets/leading/polygon.png';
// import ImgBinance from 'assets/leading/binance.png';
// import ImgEtherium from 'assets/leading/etherium.png';
// import ImgMoonbeam from 'assets/leading/moonbeam.png';
// import ImgHarnomy from 'assets/leading/harmony.png';
// import ImgSafeLaunch from 'assets/leading/safe-launch.png';
// import ImgHeco from 'assets/leading/heco.png';
// import ImgGDAventure from 'assets/leading/gd-venture.png';
// import ImgMorningStart from 'assets/leading/morningstart.png';
// import ImgMoonEdge from 'assets/leading/moonedge.png';


// components
import { ButtonSimple } from 'components/Button';

function LandingPage() {
  const imgDeveloperBannerRef = useRef(null);
  const imgArtistBannerRef = useRef(null);
  const imgCommunityBannerRef = useRef(null);

  useEffect(() => {
    function handleScroll() {
      const scrollTop = document.documentElement.scrollTop

      // developer
      if(scrollTop > 1249 && scrollTop < 2058) {
        imgDeveloperBannerRef.current.classList.add('animate__slideInLeft');
        imgDeveloperBannerRef.current.classList.add('opacity-100')
      }

      // artist
      if(scrollTop > 1920 && scrollTop < 2507) {
        imgArtistBannerRef.current.classList.add('animate__slideInRight');
        imgArtistBannerRef.current.classList.add('opacity-100')
      }

      // community
      if(scrollTop > 2524 && scrollTop < 2720) {
        imgCommunityBannerRef.current.classList.add('animate__slideInLeft');
        imgCommunityBannerRef.current.classList.add('opacity-100')
      }

      // reset
      if((scrollTop > 400 && scrollTop < 458) || (scrollTop > 3672 && scrollTop < 4022)  ) {
        imgDeveloperBannerRef.current.classList.remove('animate__slideInLeft');
        imgDeveloperBannerRef.current.classList.remove('opacity-100')
        imgArtistBannerRef.current.classList.remove('animate__slideInRight');
        imgArtistBannerRef.current.classList.remove('opacity-100')
        imgCommunityBannerRef.current.classList.remove('animate__slideInLeft');
        imgCommunityBannerRef.current.classList.remove('opacity-100')
      }
    }

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  return (
    <>
      <div className='container'>
        <div className='empowering'>
          <div className='empowering_creator'>
            <h1 className='heading-1'>
              Empowering Creators
            </h1>
            <h5 className='heading-6'>
              Ambros is a high speed, low fee EVM sidechain that enables creators to share in the value they create.
            </h5>
            <ButtonSimple 
              text="Build Now"
            />
          </div> 
          <div className='empowering_banner animate__animated animate__bounceInDown'>
            <img src={ImgHeroBanner} alt="Hero Banner" />
          </div> 
        </div>  
      </div>

      <div className='container'>
        <div className='creators'>
          <div className='creators_top'>
            <h5 className='heading-4'>
              Bring value back to creators
            </h5>
            <h6 className='heading-6 mt-8'>
              You earn from and retain control over whatever you build on
            </h6>
          </div>
          <div className='creators_content'>
            <div className='creators_values creators_bg_gradient animate__animated animate__fadeIn animate__delay-1s'>
              <img src={ImgCreators01} alt="Creators" />
              <h5 className='heading-6'>
                Earn transaction fees
              </h5>
              <h6 className='heading-9 mt-8'>
                Transaction fees are shared between miners and content creators.
              </h6>
            </div>
            <div className='creators_values creators_bg_gradient animate__animated animate__fadeIn animate__delay-1s'>
              <img src={ImgCreators02} alt="Creators"/>
              <h5 className='heading-6'>
                Governance of the chain
              </h5>
              <h6 className='heading-9 mt-8'>
                Ambros is centered around its creators. You'll be able to vote on new features and participate
              </h6>
            </div>
            <div className='creators_values creators_bg_gradient animate__animated animate__fadeIn animate__delay-1s'>
              <img src={ImgCreators03} alt="Creators"/>
              <h5 className='heading-6'>
                Design your own economy
              </h5>
              <h6 className='heading-9 mt-8'>
                Ambros provides the flexible transaction fee model in which creators have full contro
              </h6>
            </div>
          </div>
        </div>
      </div>     

      <div className='container'>
        <div className='developers'>
          <h3 className='heading-4 text-center'>
            Who are
          </h3>
          <div className='developers_lead heading-2 text-center'>
            Creators?
          </div>
          <div className='developers_content'>
            <div ref={imgDeveloperBannerRef} className='developers_banner animate__animated opacity-0'>
              <img src={ImgDeveloperBanner} alt="Hero Banner" />
            </div> 
            <div className='developers_des'>
              <h3 className='heading-3'>
                Developers
              </h3>
              <h6 className='heading-6'>
                Freely create different kinds of dapp on Ambros. It is EVM compatible, lightning-fast 
                and low-fee. Free-to-play games are now financially viable with the flexible transaction fee model
              </h6>
            </div> 
          </div> 
        </div>   
      </div>

      <div className='artist'>
        <div className='container'>
          <div className='artist_content'>
            <div className='artist_des'>
              <h3 className='heading-3 text-color-white'>
                Artists
              </h3>
              <h6 className='heading-6 text-color-white'>
                We provide the Ambros Portal to host your NFTs. Any interactions with the NFT 
                (such as likes, shares, and comments) are saved on-chain to add value to the NFTs. Therefore, 
                those interactions bring you financial incentives.
              </h6>
            </div> 
            <div ref={imgArtistBannerRef}  className='artist_banner animate__animated opacity-0'>
              <img src={ImgArtistBanner} alt="Hero Banner" />
            </div> 
          </div> 
        </div>
      </div> 

      <div className='container'>
        <div className='community'>
          <div className='community_content'>
            <div ref={imgCommunityBannerRef} className='community_banner animate__animated opacity-0'>
              <img src={ImgCommunityBanner} alt="Hero Banner" />
            </div> 
            <div className='community_des'>
              <h3 className='heading-3'>
                Community
              </h3>
              <h6 className='heading-6'>
                The interactions (e.g game ranking, battle history, comments on NFTs ..) 
                are saved onchain and anyone looking for them brings you the fee.
              </h6>
            </div> 
          </div> 
        </div>   
      </div>

      <div className='creators_bg' />

      <div className='container'>
        <div className='creators'>
          <h3 className='heading-11 text-center'>
            Key features
          </h3>
          <div className='creators_content mt-96'>
            <div className='creators_values animate__animated animate__fadeIn animate__delay-3s'>
              <img src={ImgFeature01} alt="Creators" />
              <h5 className='heading-6'>
                Fast and constant low fee
              </h5>
              <h6 className='heading-9 mt-8'>
                Within 1 second confirmation time, up to 50k transactions per second can be processed with a 
                constant low fee via Proof of Staked Authority (PoSA) and the dual token model.
              </h6>
            </div>
            <div className='creators_values animate__animated animate__fadeIn animate__delay-3s'>
              <img src={ImgFeature02} alt="Creators"/>
              <h5 className='heading-6'>
                Flexible transaction fee model
              </h5>
              <h6 className='heading-9 mt-8'>
                Fees are shared between miners and contract owners. It can also be 
                programmatically configured inside the contract. Zero-fee transactions are made feasible.
              </h6>
            </div>
            <div className='creators_values animate__animated animate__fadeIn animate__delay-3s'>
              <img src={ImgFeature03} alt="Creators"/>
              <h5 className='heading-6'>
                Easy to adopt
              </h5>
              <h6 className='heading-9 mt-8'>
                EVM compatible with native Interoperability. Asset interaction can be done 
                cross-chain via a delegation mechanism.
              </h6>
            </div>
          </div>
        </div>
      </div>  

      <div className='container'>
        <div className='compare'>
          <h2 className='heading-2 text-center'>
            Comparing
          </h2>
          <h4 className='heading-10 text-center'>
            to other Blockchains
          </h4>

          <div className='compareTable mt-48'>
            <table cellPadding={0} cellSpacing={0} border="0">
              <tbody>
                <tr>
                  <th rowSpan="2" className='text-left'>
                    <h6 className='heading-7-bold'>EVM Compatible</h6>
                  </th>
                  <th className='is-active'>
                    <div className='compareTable_chain'>
                      <IconAmbros />
                      <h6 className='heading-7-bold'>Ambros</h6>
                    </div>
                  </th>
                  <th>
                    <div className='compareTable_chain'>
                      <IconEtherium />
                      <h6 className='heading-7'>Ethereum</h6>
                    </div>
                  </th>
                  <th>
                    <div className='compareTable_chain'>
                      <IconBinance />
                      <h6 className='heading-7'>BNB</h6>
                    </div>
                  </th>
                  <th>
                    <div className='compareTable_chain'>
                      <img src={IconSolana} alt="Solana" />
                      <h6 className='heading-7'>Solana</h6>
                    </div>
                  </th>
                  <th>
                    <div className='compareTable_chain'>
                      <img src={IconRonin} alt="Ronin" />
                      <h6 className='heading-7'>Ronin</h6>
                    </div>
                  </th>
                </tr>
                
                <tr>
                  <td className='text-center pl-0 border-left is-active'>
                    <IconChecked />
                  </td>
                  <td>
                    <IconChecked />
                  </td>
                  <td>
                    <IconChecked />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconChecked />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6 className='heading-7-bold'>Fee for creator</h6>
                  </td>
                  <td className='is-active'>
                    <IconChecked />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6 className='heading-7-bold'>Flexible transaction fee</h6>
                  </td>
                  <td className='is-active'>
                    <IconChecked />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6 className='heading-7-bold'>Finality</h6>
                  </td>
                  <td className='is-active'>
                    <IconChecked />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6 className='heading-7-bold'>Within 1s Confirmation</h6>
                  </td>
                  <td className='is-active'>
                    <IconChecked />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6 className='heading-7-bold'>Constant Low Fee/TX</h6>
                  </td>
                  <td className='is-active'>
                    <IconChecked />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6 className='heading-7-bold'>Native Interoperability</h6>
                  </td>
                  <td className='is-active'>
                    <IconChecked />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                  <td>
                    <IconReject />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='dual'>
          <h2 className='heading-4 text-center'>
            Dual token model
          </h2>
          <h4 className='heading-6 text-center'>
            Separate the conflict between governance & fee. Support the stable gas fee model
          </h4> 

          <div className='dualToken mt-48'>
            <div className='dualToken_item ichor'>
              <div className='dualToken_top'>
                <img src={DualIchor} alt="Ichor" />
                <h3 className='heading-11-bold text-color-content-inverse-primary'>
                  ICHOR
                </h3> 
              </div>
              <h6 className='heading-7-bold text-color-content-inverse-primary mt-8'>
                Stable coin used for transaction fee. Designed to scale with TX demand
              </h6> 
              <div className='dualToken_line' />
              <h5 className='heading-6 text-color-content-inverse-primary'>
                Supply: UNCAPPED <br />
                Buy ICHOR: From <a href="">Portal</a> by exchaing stable coin (USDT, USDC, DAI)
              </h5> 
            </div>
            <div className='dualToken_item ambros'>
              <div className='dualToken_top'>
                <img src={DualAmbros} alt="Ichor" />
                <h3 className='heading-11-bold text-color-content-inverse-primary'>
                  AMBROS
                </h3> 
              </div>
              <h6 className='heading-7-bold text-color-content-inverse-primary mt-8'>
                Governance token for Ambros Chain, it is used to vote for chain 
                decision and used in Proof of Staked Authority (PoSA)
              </h6> 
              <div className='dualToken_line' />
              <h5 className='heading-6 text-color-content-inverse-primary'>
                Supply: Fixed at 200M <br />
                Buy AMBROS: From DEX and CEX 
              </h5> 
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='roadmap'>
          <h2 className='heading-4 text-center'>
            Roadmap
          </h2>
          <div className='roadmap_content mt-48'>
            <div className='roadmap_item'>
              <IconRoadmap />
              <h5 className='heading-5 text-color-white'>H1 2022</h5>
              <ul className='heading-9 text-color-white'>
                <li>Whitepaper and</li>
                <li>fundamental layer concept</li>
                <li>Private fund raising</li>
              </ul>
            </div>
            <div className='roadmap_item'>
              <IconRoadmap />
              <h5 className='heading-5 text-color-white'>H2 2022</h5>
              <ul className='heading-9 text-color-white'>
                <li>BCO and AMBROS staking</li>
                <li>Atlas testnet</li>
                <li>Validator bootstrap infrastructure and integration</li>
              </ul>
            </div>
            <div className='roadmap_item'>
              <IconRoadmap />
              <h5 className='heading-5 text-color-white'>H1 2023</h5>
              <ul className='heading-9 text-color-white'>
                <li>Launch Ambros 1.0 (Athena)</li>
                <li>Nectar portal (stable coin wrapped portal)</li>
                <li>Project & creator onboarding program</li>
              </ul>
            </div>
            <div className='roadmap_item'>
              <IconRoadmap />
              <h5 className='heading-5 text-color-white'>H2 2023</h5>
              <ul className='heading-9 text-color-white'>
                <li>Launch Ambros 2.0 (Artemis): 2-way interoperability</li>
                <li>Cross-chain marketplace adoption</li>
                <li>Multi-chain NFT portal for content creator</li>
              </ul>
            </div>
            <div className='roadmap_item'>
              <IconRoadmap />
              <div className='d-flex items-end'>
                <h5 className='heading-5 text-color-white mr-8'>H1 2024</h5>
                <span className='heading-6 text-color-white'>onward</span>
              </div>
              
              <ul className='heading-9 text-color-white'>
                <li>Launch Ambros 3.0 (Aphrodite): dynamic fee split</li>
                <li> Smooth onboarding experience for new crypto users</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    {/*
      <div className='container'>
        <div className='leading'>
          <h2 className='heading-4 text-center'>
            Leading Validators
          </h2>

          <div className='leadingContent mt-48'>
            <div className='leading_item'>
              <img src={ImgEversecapital} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgBasicCaptial} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgAnimoca} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgDoulbePeek} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgDline} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgPolygon} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgBinance} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgEtherium} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgMoonbeam} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgHarnomy} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgSafeLaunch} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgHeco} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgGDAventure} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgMorningStart} alt="" />
            </div>
            <div className='leading_item'>
              <img src={ImgMoonEdge} alt="" />
            </div>
          </div>
        </div>
      </div>
    */}
      <div className='container'>
        <div className='subcribe'>
          <div className='subcribe_info'>
            <h3 className='subcribe_heading heading-10-black'>
              Don’t miss out <span>, join now for free</span>
            </h3>
            <h4 className='heading-7'>The lorem Separate the conflict between governance & fee. Support the stable gas fee model</h4>
            <div className='subcribe_info_letter'>
              <img src={ImgLetter} alt="" />
            </div>
            <div className='subcribe_checkbox'>
              <FormControlLabel 
                control={<Checkbox />} 
                label={
                  <div className='text-color-content-primary'>
                    Agree to our <a href="#" className='text-color-content-link-primary'>Privacy Policy</a>
                  </div>
                }
              />
            </div>
            <div className='subcribe_field'>
              <div className='subcribe_input'>
                <input className='heading-6 text-content-secondary' type="email" placeholder='Your email' />
              </div>
              <Button variant="contained">Subcribe</Button>
            </div>
          </div>
          <div className='subcribe_letter'>
            <img src={ImgLetter} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default LandingPage