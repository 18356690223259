import React from 'react';
import clsx from 'clsx';
import { useClickAway } from 'react-use';
import { useNavigate } from 'react-router-dom';

// assets
import BannerTeam from 'assets/career-path/banner-team.jpg';
import { ReactComponent as IconSearch } from "assets/svg/icon-search.svg";
import { ReactComponent as IconArrowRight } from "assets/svg/icon-arrow-right.svg";
import ImgDesign from 'assets/career-path/design.png';
import ImgGame from 'assets/career-path/game.png';
import ImgQA from 'assets/career-path/qa.png';
import ImgAcquistion from 'assets/career-path/acquistion.png';
import ImgNetwork from 'assets/career-path/network.png';

// components
import { ButtonSimple } from 'components/Button';
import { SelectDefault } from 'components/Select';

const locations = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Remote',
    value: 'remote'
  },
  {
    label: 'Vietnam',
    value: 'vn'
  },
  {
    label: 'Singapore',
    value: 'singapore'
  },
  {
    label: 'USA',
    value: 'usa'
  }
]

const levels = [
  {
    label: 'Senior',
    value: 'senior'
  },
  {
    label: 'Junior',
    value: 'junior'
  },
  {
    label: 'Middle',
    value: 'middle'
  }
]

const postions = [
  {
    img: ImgDesign,
    location: 'Vietnam',
    level: 'Senior',
    title: 'UI/UX Design',
    href: ''
  },
  {
    img: ImgGame,
    location: 'Remote',
    level: 'Fresher',
    title: 'Gameplay Programmer',
    href: ''
  },
  {
    img: ImgDesign,
    location: 'Remote',
    level: 'Senior',
    title: 'UI Scripter',
    href: ''
  },
  {
    img: ImgQA,
    location: 'Remote',
    level: 'Senior',
    title: 'QA Engineer',
    href: 'Junior'
  },
  {
    img: ImgAcquistion,
    location: 'Singapore',
    level: 'Senior',
    title: 'Talent Acquisition Partner',
    href: ''
  },
  {
    img: ImgDesign,
    location: 'Vietnam',
    level: 'Senior',
    title: 'UI/UX Design',
    href: ''
  },
  {
    img: ImgGame,
    location: 'Remote',
    level: 'Fresher',
    title: 'Gameplay Programmer',
    href: ''
  },
  {
    img: ImgDesign,
    location: 'Remote',
    level: 'Senior',
    title: 'UI Scripter',
    href: ''
  },
  {
    img: ImgQA,
    location: 'Remote',
    level: 'Senior',
    title: 'QA Engineer',
    href: 'Junior'
  },
  {
    img: ImgAcquistion,
    location: 'Singapore',
    level: 'Senior',
    title: 'Talent Acquisition Partner',
    href: ''
  },
  {
    img: ImgDesign,
    location: 'Vietnam',
    level: 'Senior',
    title: 'UI/UX Design',
    href: ''
  },
  {
    img: ImgGame,
    location: 'Remote',
    level: 'Fresher',
    title: 'Gameplay Programmer',
    href: ''
  },
  {
    img: ImgDesign,
    location: 'Remote',
    level: 'Senior',
    title: 'UI Scripter',
    href: ''
  },
  {
    img: ImgQA,
    location: 'Remote',
    level: 'Senior',
    title: 'QA Engineer',
    href: 'Junior'
  },
  {
    img: ImgAcquistion,
    location: 'Singapore',
    level: 'Senior',
    title: 'Talent Acquisition Partner',
    href: ''
  },
  {
    img: ImgNetwork,
    location: 'Singapore',
    level: 'Senior',
    title: 'Talent Acquisition Partner',
    href: ''
  }
]

function CareerPath() {
  const navigate = useNavigate();
  const [titleLocation, setTitleLocation] = React.useState('');
  const [titleLevel, setTitleLevel] = React.useState('');
  const [openSearch, setOpenSearch] = React.useState(false);
  // refs
  const searchRef = React.useRef(null);

  const onSelectedLocation = (option)  => {
    console.log('onSelectedLocation: ', option)
    setTitleLocation(option);
  }

  const onSelectedLevel = (option)  => {
    console.log('onSelectedLevel: ', option)
    setTitleLevel(option);
  }

  useClickAway(
    searchRef,
    () => {
      if (openSearch) {
        setOpenSearch(false);
      }
    },
    ['mousedown'],
  );

  function handleGoToDetail() {
    navigate('/career-path/ui-ux-design')
  }

  return (
    <>
      <div className='career_banner'>
        <h1 className='heading-1 text-color-white'>Work with Us</h1>
      </div>

      <div className='container'>
        <div className='careerLife d-flex items-center justify-between mt-64'>
          <div className='careerLife_info'>
            <h3 className='heading-3'>Life at Ambros</h3>
            <h5 className="heading-6 mt-6 mb-20">
              Ambros is a high speed, low fee EVM sidechain that enables creators to share in the value they create.
            </h5>
            <ButtonSimple 
              text="See more"
            />
          </div>
          <div className='w-full'>
            <img src={BannerTeam} alt="Team" className='w-full' />  
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='career_heading mt-70'>
          <h3 className="heading-3">Openings</h3>
        </div>

        <div ref={searchRef} className={clsx(
          "careerSearch",
          openSearch && 'openSearch'
        )}>
          <div className='careerSearch_position'>
            <IconSearch className='iconSvg_white' onClick={() => setOpenSearch(true)}/>
            <input type="text" placeholder='Position' className='heading-6' />
          </div>
          <div className='careerSearch_select location'>
            <SelectDefault 
              placeholder="Location"
              width="250px"
              options={locations}
              label={titleLocation.label}
              selectedIndex={locations.findIndex(location => location.value === titleLocation.value)}
              onSelected={option => onSelectedLocation(option)}
              renderOptions={option => (
                <>
                  {option.label}
                </>
              )}
            />
          </div>
          <div className='careerSearch_select'>
            <SelectDefault 
              placeholder="Level"
              width="250px"
              options={levels}
              label={titleLevel.label}
              selectedIndex={levels.findIndex(level => level.value === titleLevel.value)}
              onSelected={option => onSelectedLevel(option)}
              renderOptions={option => (
                <>
                  {option.label}
                </>
              )}
            />
          </div>
            
        </div>

        <div className='careerOpens'>
          <div className='careerList'>
            {postions.map((postion, index) => (
              <div key={index} className='careerBox careerBox_border careerBox_item round-16' onClick={() => handleGoToDetail() }>
                <div className='careerBox_container'>
                  <img src={postion.img} alt={postion.title} />
                  <div className='careerBox_info'>
                    <h5 className='heading-7 text-color-content-secondary'>{postion.location}</h5>
                    <h4 className='heading-12'>{postion.title}</h4>
                    <h6 className='heading-7 text-color-content-accent-primary'>{postion.level}</h6>
                  </div>               
                </div>
                <div className='careerBox_arrow'>
                  <IconArrowRight className='iconSvg_white' />
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    </>
  )
}

export default CareerPath