import React from 'react'

function TextFieldSimple({ label, ...props }) {
  return (
    <div className='textFieldSimple'>
      <label className='heading-9 text-color-content-primary'>{label}</label>
      <input className='heading-8 text-color-content-secondary' type="text" {...props} />
    </div>
  )
}

export default TextFieldSimple