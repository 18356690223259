import React, { useState } from 'react';
import { Link } from "react-router-dom";
import clsx from 'clsx';

// assets
import { ReactComponent as AmbrosLogo } from "assets/svg/ambros-logo.svg";

// components
import ThemeControl from 'components/ThemeControl';

function Header() {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className='container'>
      <div className={clsx(
        'header',
        openMenu && 'header_mobile'
      )}>
        <div className='header_logo'>
          <AmbrosLogo />
          <div 
            className={clsx(
              "header_iconMenu",
              openMenu && 'is-show'
            )}
            onClick={() => setOpenMenu(prevState => !prevState)}
          >
            <div className='header_iconMenu_line' />
          </div>
        </div>
        <div className="header_menu">
          <nav className='header_menu_nav'>
            <ul>
              <li className='heading-8'>
                <Link to="" onClick={() => setOpenMenu(false)}>Home</Link>
              </li> 
              <li className='heading-8'>
                <Link to="" onClick={() => setOpenMenu(false)}>Ambros & Ichor</Link>
              </li> 
              <li className='heading-8'>
                <Link to="" onClick={() => setOpenMenu(false)}>Ecosystem</Link>
              </li> 
              <li className='heading-8'>
                <Link to="" onClick={() => setOpenMenu(false)}>Developers</Link>
              </li> 
              <li className='heading-8'>
                <Link to="" onClick={() => setOpenMenu(false)}>Blog</Link>
              </li> 
              <li className='heading-8'>
                <Link to="/career-path" onClick={() => setOpenMenu(false)}>Career</Link>
              </li> 
              <li className='header_menu_border' />
              <li className='heading-8'>
                <Link to="" onClick={() => setOpenMenu(false)}>Portal</Link>
              </li> 
              <li className='heading-8'>
                <Link to="" onClick={() => setOpenMenu(false)}>Bridge</Link>
              </li> 
              <li className='heading-8'>
                <Link to="" onClick={() => setOpenMenu(false)}>Explorer</Link>
              </li> 
            </ul>  
          </nav> 
        </div>
        <div className='header_theme'>
          <ThemeControl />
        </div>
      </div>

      <div 
        className={clsx(
          'header_blur',
          openMenu && 'is-show'
        )} 
        onClick={() => setOpenMenu(false)}
      />
    </div>
  )
}

export default Header