import React from 'react';
import {
  Outlet,
  useLocation
} from "react-router-dom";

// components
import Header from "components/Header";
import Footer from 'components/Footer';

function Layout() {
  const location = useLocation();
  console.log(location)

  React.useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [location])

  return (
    <>
      <Header />

      <main>
        <Outlet />
      </main>

      <Footer />
    </>
  );
}

export default Layout