
import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useClickAway } from 'react-use';
import { Portal } from 'react-portal';

// assets
import { ReactComponent as IconArrowDown } from "assets/svg/icon-arrow-down.svg";
import { ReactComponent as IconSelected } from "assets/svg/icon-selected.svg";

const useStyles = makeStyles({
  selectDefault: {
    cursor: 'pointer',
    position: 'relative',
    width: props => props.width
  },
  selectDefault_placeHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 24,
    paddingRight: 30
  },
  selectDefault_options: {
    position: 'absolute',
    backgroundColor: 'var(--bg-elevation)',
    border: '0.5px solid var(--line-primary)',
    boxShadow: '0px 8px 16px var(--s3-top)',
    borderRadius: 12,
    width: '100%',
    padding: '20px 16px',
    marginTop: 17,
    overflow: 'auto',
    maxHeight: 300,
    zIndex: 1,
    '& li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover': {
        color: 'var(--content-accent-primary)'
      },
      '& + li': {
        marginTop: 24
      },
      '&.is-active': {
        color: 'var(--content-accent-primary)'
      }
    }
  }
});

function SelectDefault({ label, selectedIndex, placeholder, options = [], onSelected, renderOptions, width = 'auto' }) {
  const classes = useStyles({ width });
  const [isOpen, setIsOpen] = React.useState(false);
  // refs
  const selectRef = React.useRef(null);

  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  useClickAway(
    selectRef,
    () => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
    ['mousedown'],
  );

  const handleSelected = (option) => () => {
    onSelected(option);
    setIsOpen(false);
  }

  return (
    <>
      <div 
        ref={selectRef}
        className={clsx(
          classes.selectDefault,
          "selectDefaultScss"
        )}
      >
        <div className={classes.selectDefault_placeHolder} onClick={handleToggleOpen}>
          <h6 className='heading-6'>{label || placeholder}</h6>
          <IconArrowDown className='iconSvg_white' />
        </div>
        
        {isOpen && (
          <ul className={clsx(
            classes.selectDefault_options,
            'heading-8'
          )}>
            {options.map((option, index) => (
              <li 
                key={index} 
                onClick={handleSelected(option)}
                className={clsx(
                  selectedIndex === index && 'is-active'
                )}
              >
                <span>{renderOptions(option)}</span>
                {selectedIndex === index && <IconSelected />}
              </li>
            ))}
          </ul>
        )}
      </div>

      {isOpen && (
        <Portal>
          <div className='portal'>
            <div className='portal_blur' onClick={() => setIsOpen(false)} />
            <div className='portal_container'>
              <ul className={clsx(
                classes.selectDefault_options,
                'portal_options',
                'heading-8'
              )}>
                {options.map((option, index) => (
                  <li 
                    key={index} 
                    onClick={handleSelected(option)}
                    className={clsx(
                      selectedIndex === index && 'is-active'
                    )}
                  >
                    <span>{renderOptions(option)}</span>
                    {selectedIndex === index && <IconSelected />}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Portal>
      )}

     
    </>
  )
}

export default SelectDefault