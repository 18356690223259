import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

// context
import { GameProvider } from 'context/GameContext';

// styles
import 'animate.css';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <GameProvider>
    <div className='wrapper'>
      <App />
    </div>
  </GameProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
