import React from 'react'

function ButtonSimple({ text = 'Submit', minWidth, ...props }) {
  return (
    <button 
      type="button" 
      className="buttonSimple" 
      style={{
        minWidth: minWidth
      }}
      {...props}
    >
      <h6 className='heading-6'>{text}</h6>
    </button>
  )
}

export default ButtonSimple